<template>
  <v-dialog v-model="isLoading" max-width="400" persistent>
    <v-card class="py-12 px-10">
      <v-row v-if="!isUploadError" justify="center">
        <v-progress-circular
          indeterminate
          color="black"
          value="100"
          size="200"
        />
      </v-row>
      <p class="text-center mt-12">
        {{ message }}
      </p>
      <p v-if="totalNumber !== 0" class="text-center mt-12">
        {{ totalNumber }}枚中{{ processNumber }}枚完了しました
      </p>
      <v-btn v-if="isUploadError" to="/videos">一覧に戻る</v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "BulkUpload",

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: "",
    },
    totalNumber: {
      type: Number,
      default: 0,
    },
    processNumber: {
      type: Number,
      default: 0,
    },
    isUploadError: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.v-progress-circular {
  margin: 1.5rem;
}
</style>
